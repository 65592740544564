<!-- Header Structure -->
<template>
  <div id="footer" class="sticky-footer pt-2">
    <!-- Main -->
    <div class="container">
      <div class="row mt-2">
        <div class="col-lg-3 col-sm-6 col-xs-12 p-0">
          <div
            class="row"
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              class="footer-logo"
              src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/d3700893b85a68ba4f6877ce9102962b.svg"
              width="300"
              alt="logo"
            />
          </div>
          <div class="container row pl-4 px-4 m-0">
            <div class="col-12 p-0">
              <div class="text-widget text-white">
                <span class="text-white">
                  <v-icon dark :color="'#fff'" :class="'icon'"> mdi-cellphone </v-icon>
                  Tlf: +1 (424) 253-0764 <br />
                  <v-icon dark :color="'#fff'" :class="'icon'"> mdi-email-check </v-icon>
                  E-Mail:
                  <a href="mailto:info@xpofranchise.com" rel="noopener" class="text-white"
                    >info@xpofranchise.com</a
                  ></span
                >
              </div>
            </div>
            <social id="social"></social>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-xs-12 px-4">
          <h5 style="color: #fff">Directorio</h5>
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/franquicias"
                >Guía de Franquicias</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/franquicias-master"
                >Franquicias Master disponibles</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/asociaciones-franquicias"
                >Asociaciones de Franquicias</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/consultores">Consultores</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/proveedores">Proveedores</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/oferta-inmobiliaria"
                >Oferta Inmobiliaria</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-sm-6 col-xs-12 px-4">
          <h5 style="color: #fff">Actualidad</h5>
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/noticias">Noticias</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/eventos">Eventos</router-link>
            </li>
            <li>
              <router-link class="text-white" to="/blog">Blog</router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-sm-6 col-xs-12 px-4">
          <h5 style="color: #fff">Principales aliados</h5>
          <ul class="footer-links">
            <li>
              <router-link class="text-white" to="/">Franchising Cloud</router-link>
            </li>
            <li>
              <a class="text-white" href="https://cogfranquicias.com" rel="noopener"
                >COG Franquicias</a
              >
            </li>
            <li>
              <router-link class="text-white" to="/ediciones-revista"
                >Revista Su Franquicia</router-link
              >
            </li>
            <li>
              <router-link class="text-white" to="/woki">WOKI Consulting</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights text-white" style="margin: 0px; padding: 5px 0px">
            Copyright &copy; 2021
            <a
              href="https://cogfranquicias.com"
              target="_blank"
              rel="noopener"
              style="color: #fff"
              >COG Franquicias C.A.</a
            >
            Todos los derechos reservados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Social from 'Components/globalFrontendComponents/Social'

  export default {
    components: {
      Social,
    },
  }
</script>
<style lang="scss" scoped>
  #footer {
    // background: linear-gradient(to left bottom, #003a56, #009fe3, #003a56);
    background: radial-gradient(circle, #2293c3, #003a56);
  }
  .icon {
    width: auto;
  }
  .copyrights {
    color: #707070;
    border-top: 1px solid rgba(255, 255, 255, 0.09);
    margin-top: 30px;
    text-align: center;
    padding: 20px 0;
    display: inline-block;
    width: 100%;
  }
  .social-container h5 {
    color: white;
  }
  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    width: 100%;
    padding: 5px;
  }
  #footer ul.footer-links {
    width: 100%;
  }
</style>
