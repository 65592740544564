<!-- App Main Structure -->
<template>
  <div id="wrapper">
    <!--Header Frontend Panel -->
    <app-header
      :key="headerIndex"
      :fixed="fixedHeader"
      @updateCountry="updateCountry($event)"
    ></app-header>

    <!-- Main Container -->
    <router-view :country="country" :key="$route.fullPath"></router-view>

    <!-- Footer Frontend Panel -->
    <app-footer v-if="isFooter"></app-footer>
  </div>
</template>

<script>
  import Header from 'Components/Header/Header.vue'
  import Footer from 'Components/Footer/Footer.vue'

  export default {
    name: 'FrontendPanel',
    data() {
      return {
        headerIndex: 1,
        isFooter: true,
        url: this.$route.path,
        country: JSON.parse(localStorage.getItem('selectedCountry')),
      }
    },
    components: {
      appHeader: Header,
      appFooter: Footer,
    },
    watch: {
      fixedHeader() {
        this.headerIndex++
      },
    },
    computed: {
      fixedHeader() {
        if (this.$route.name != 'CompanyDetail') {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      updateCountry(country) {
        this.country = country
      },
      onScrollEvent() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0
        if (scrollTop >= 400) {
          document.getElementById('wrapper').classList.add('header-fixed')
        } else {
          document.getElementById('wrapper').classList.remove('header-fixed')
        }
      },
    },
    created() {
      window.addEventListener('scroll', this.onScrollEvent)
    },
    destroyed() {
      window.removeEventListener('scroll', this.onScrollEvent)
    },
  }
</script>
